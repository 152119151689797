import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config,  schoolName, familyCode} from '../component/include'
import Select from 'react-select'
import { userToken } from '../component/authentication'

import { useDispatch, useSelector } from 'react-redux'
const StudentTranscript =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
    
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
const staff  = useSelector(state => state.rootReducer.staff);

 const [student, setStudent] = useState([]);
 const [studentList, setStudentList] = useState([]);
    const [first, setFirst]=useState([])
    const [second, setSecond]=useState([])
    const [third, setThird]=useState([])

    const [firstTerm, setFirstTerm]=useState([])
    const [secondTerm, setSecondTerm]=useState([])
    const [thirdTerm, setThirdTerm]=useState([])


    const [isSubmitting, setIsSubmitting] = useState(false);
    const [result, setResult] = useState({
        studentClass:[],
        examtypeA:[],
        examtypeB:[],
        examtypeC:[],
        student:[],
        firstRemark:'',
        secondRemark:'',
        thirdRemark:''
    })

    const [settings, setSettings]=useState([])

    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
         
        if(values.examtypeA.length===0 && values.examtypeB.length ===0 && values.examtypeC.length===0 ){
            errors.examtypeA ='Please select at lease one examtype';
            Alerts('Error!', 'danger', 'Please select at lease one examtype')
        } 
        if(values.student.length===0){
            errors.student ='Please select student';
        } 
        return errors;
        }
        
      
        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data)
              
            })
        }
        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const fetchRemark =()=>{
        var sql ="SELECT count(*), (select teacherRemark from tbl_result_remarks where studentCode = '"+result.student.value+"' and examType = '"+result.examtypeA.value+"'  ) as firstRemark, (select teacherRemark from tbl_result_remarks where studentCode = '"+result.student.value+"' and examType = '"+result.examtypeB.value+"' ) as secondRemark, (select teacherRemark from tbl_result_remarks where studentCode = '"+result.student.value+"' and examType = '"+result.examtypeC.value+"' ) as thirdRemark from tbl_result_remarks " ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setResult({...result, firstRemark:response.data[0].firstRemark, secondRemark:response.data[0].secondRemark, thirdRemark:response.data[0].thirdRemark })
        
        structure.current.scrollIntoView({ behavior: "smooth" });
    }else{
        setResult({...result, firstRemark:'', secondRemark:'', thirdRemark:'' })
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}

    const handleSelectExamtypeA =(option, action)=>{     
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
       fetchAnalysisA(option.value)
    }

    const handleSelectExamtypeB =(option, action)=>{     
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
       fetchAnalysisB(option.value)
       
    }
    const handleSelectExamtypeC =(option, action)=>{     
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
       fetchAnalysisC(option.value)
    }

   

    const handleSelectStudent =(option, action)=>{     
        setResult({...result, [action.name]:option});
        setErrors({...errors, [action.name]:''})
       setStudent(studentList.filter(item=>item.code===option.value)[0])
       setFirst('')
       setSecond('')
       setThird('')
       
    }


   
    
    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        fetchRemark()
        }
        },[errors])

     

        const fetchStudentList =()=>{
            setNotice({...notice, isLoading: true}) 
            var sql ="SELECT e.teacher, s.passport, s.code, s.family_name, s.applicationID, s.gender, s.admissionNumber, s.registerNumber, s.studentName, s.sectionID, s.classID from tbl_students s, tbl_exam_result e where e.studentCode = s.code group by s.code"
        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length>0){

                setStudentList(result.data.filter(item=>item.family_name===familyCode))
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
           
        })
        }



        const fetchAnalysisA =(exam)=>{
            setNotice({...notice, isLoading: true})
            var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+exam+"'  group by r.subjectCode  order by r.displayOrder ASC" ;
    
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                if(response.data.length!==0){
                var subj = response.data;
    
                let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
             let final =   'SELECT  rank() over (order by sum(e.total) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code,  s.studentName, sum(e.total) as obtained, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" group by e.studentCode '
    
               
            const fd = new FormData();
            fd.append("sql", final);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                 if(response.data.length!==0){
            setFirstTerm(response.data.filter(item=>item.code===result.student.value)[0])
                }  
            })
            }
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
            })

        }


        const fetchAnalysisB =(exam)=>{
        
            var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+exam+"'  group by r.subjectCode  order by r.displayOrder ASC" ;
    
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                if(response.data.length!==0){
                var subj = response.data;
    
                let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
             let final =   'SELECT  rank() over (order by sum(e.total) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code,  s.studentName, sum(e.total) as obtained, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" group by e.studentCode '
    
            const fd = new FormData();
            fd.append("sql", final);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                if(response.data.length!==0){
           setSecondTerm(response.data.filter(item=>item.code===result.student.value)[0])
               }  
           })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            })
            }
            })

        }
        const fetchAnalysisC =( exam)=>{

            var sql ="SELECT s.subjectName, s.abbreviation, s.code, r.examCode from tbl_exam_result r, tbl_subject s where r.subjectCode = s.code and r.examCode ='"+exam+"' group by r.subjectCode  order by r.displayOrder ASC" ;
    
            const fd = new FormData();
            fd.append("sql", sql);
            fd.append('jwt', Token)
            let url = ServerUrl+'/fetchBySql_controller';
            axios.post(url, fd, config).then(response=>{
                if(response.data.length!==0){
                var subj = response.data;
    
                let sqlString = subj.map(data=>"sum(case when e.subjectCode = '"+data.code+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
             let final =   'SELECT  rank() over (order by sum(e.total) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code,  s.studentName, sum(e.total) as obtained, e.maxMark, e.absent, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+exam+'" group by e.studentCode '
    
             const fd = new FormData();
             fd.append("sql", final);
             fd.append('jwt', Token)
             let url = ServerUrl+'/fetchBySql_controller';
             axios.post(url, fd, config).then(response=>{
              if(response.data.length!==0){
         setThirdTerm(response.data.filter(item=>item.code===result.student.value)[0])
             }  
         })
             .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
             })
               
            }
            })

        }

       

        const fetchResult =()=>{
            setNotice({...notice, isLoading: true}) 
            fetchResultB()
            fetchAnalysisA(result.examtypeA.value)
            fetchAnalysisB(result.examtypeB.value)
            fetchAnalysisC(result.examtypeC.value)

            var sql ="SELECT s.subjectName, e.total, e.grade from tbl_exam_result e, tbl_subject s where e.subjectCode = s.code and e.examCode ='"+result.examtypeA.value+"'  and e.studentCode ='"+result.student.value+"'   order by e.displayOrder ASC" ;


         const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
             if(result.data.length!==0){
        setFirst(result.data)
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
            } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
        }

        const fetchResultB =()=>{
            fetchResultC()
            var sql ="SELECT s.subjectName, e.total, e.grade from tbl_exam_result e, tbl_subject s where e.subjectCode = s.code and e.examCode ='"+result.examtypeB.value+"' and e.studentCode ='"+result.student.value+"'   order by e.displayOrder ASC" ;


         const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
             if(result.data.length!==0){
        setSecond(result.data)
            } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }
        const fetchResultC =()=>{
            
            var sql ="SELECT s.subjectName, e.total, e.grade from tbl_exam_result e, tbl_subject s where e.subjectCode = s.code and e.examCode ='"+result.examtypeC.value+"' and e.studentCode ='"+result.student.value+"'   order by e.displayOrder ASC" ;


         const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
             if(result.data.length!==0){
        setThird(result.data)
            } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }

        const getStaffField = (code, field) =>{   
    
            const result = staff&&staff.filter(list=>list.code===code);
            const answer = result.map((c)=>c[field] ); 
            return  String(answer);
           }

useEffect(()=>{
    fetchStudentList()
    fetchExamType()
    fetchSettings()
},[]);



        return (  
            <React.Fragment>
   <Template menu="Academics" submenu='Report'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="View Transcript">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Student Transcript</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Transcript</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

                       
     <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type 1<span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelectExamtypeA} className={errors.examtypeA ? 'form-control form-control-danger' : ''} name="examtypeA" value={result.examtypeA}  /> <span style={{color:'red'}}>{errors.examtypeA && errors.examtypeA}</span>

 
             </div>
     
     </section>
     <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type 2<span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelectExamtypeB} className={errors.examtypeB ? 'form-control form-control-danger' : ''} name="examtypeB" value={result.examtypeB}  /> <span style={{color:'red'}}>{errors.examtypeB && errors.examtypeB}</span>

 
             </div>
     
     </section>
     <section className="col-md-6">
     <div className="form-group">
          <label> Exam Type 3<span style={{color:'red'}}>*</span></label>
          <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelectExamtypeC} className={errors.examtypeC ? 'form-control form-control-danger' : ''} name="examtypeC" value={result.examtypeC}  /> <span style={{color:'red'}}>{errors.examtypeC && errors.examtypeC}</span>

 
             </div>
     
     </section>
     <section className="col-md-6">
     <div className="form-group">
						<label>Student</label>
						<Select  options={
                            studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={result.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
                                            </section> 
				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	
{result.student.length!==0?

<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
     </td>

     <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+student.gender+'.png'}} src={`${ImagesUrl}/students/${student.passport}`}  title={student.studentName} alt=''  /></td>
 
                </tr>
            </tbody>
       </table>

</div> 
                       )}

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>STUDENT ACADEMIC TRANSCRIPT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='3'>NAME OF STUDENT: <b>{student.studentName}</b></td>
                    
                    <td >ADMISSION NO: <b> {student.admissionNumber} </b></td>
                </tr>
            </tbody>
       </table></div>

       </div>

       <div className="row">
       

       <div className="col-md-4 table-responsive">
       {first.length!==0?
       <table className="table table-bordered table-full-width">
                        
                            <thead>
                            <tr><th colSpan='3'>{result.examtypeA.label}</th></tr>
                                <tr>
                                    <th>SUBJECTS</th>
                                    <th>SCORE</th>
                                    <th>GRADE</th>
                                </tr>
                            </thead>
                            <tbody>
                                {first&&first.map((data, id)=>data.absent!=='Yes' && data.total >0?
                            <tr key={id}>
                                <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.subjectName.toUpperCase()}</td>
                                <td>{data.total}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td><b>Total Score</b></td>
                                    <td colSpan='2'>{firstTerm&&firstTerm.obtained}</td>
                                </tr>
                                <tr>
                                    <td><b>Average</b></td>
                                    <td colSpan='2'>{firstTerm&&firstTerm.average}</td>
                                </tr>
                               
                                <tr>
                                    <td colSpan='3'><b>Remarks: </b>
                                    <span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{result.firstRemark}</span>
                                    </td>
                                    
                                </tr>
                            </tfoot>
                            
                    </table>:''}
           </div>
           <div className="col-md-4 table-responsive">
{second.length!==0?
<table className="table table-bordered table-full-width">
                
                     <thead>
                     <tr><th colSpan='3'>{result.examtypeB.label}</th></tr>
                         <tr>
                             <th>SUBJECTS</th>
                             <th>SCORE</th>
                             <th>GRADE</th>
                         </tr>
                     </thead>
                     <tbody>
                     {second&&second.map((data, id)=>data.absent!=='Yes' && data.total >0?
                            <tr key={id}>
                               <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.subjectName.toUpperCase()}</td>
                                <td>{data.total}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                     </tbody>
                     <tfoot>
                     <tr>
                                    <td><b>Total Score</b></td>
                                    <td colSpan='2'>{secondTerm&&secondTerm.obtained}</td>
                                </tr>
                                <tr>
                                    <td><b>Average</b></td>
                                    <td colSpan='2'>{secondTerm&&secondTerm.average}</td>
                                </tr>
                               
                                <tr>
                                    <td colSpan='3'><b> Remarks: </b> <span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{result.secondRemark}</span></td>
                                    
                                </tr>
                            </tfoot>
                 
             </table>:''}


    </div>

    <div className="col-md-4 table-responsive">
    {third.length!==0?
       <table className="table table-bordered table-full-width">
                        
                            <thead>
                            <tr><th colSpan='3'>{result.examtypeC.label}</th></tr>
                                <tr>
                                    <th>SUBJECTS</th>
                                    <th>SCORE</th>
                                    <th>GRADE</th>
                                </tr>
                            </thead>
                            <tbody>
                            {third&&third.map((data, id)=>data.absent!=='Yes' && data.total >0?
                            <tr key={id}>
                              <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.subjectName.toUpperCase()}</td>
                                <td>{data.total}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                            </tbody>
                            <tfoot>
                            <tr>
                                    <td><b>Total Score</b></td>
                                    <td colSpan='2'>{thirdTerm&&thirdTerm.obtained}</td>
                                </tr>
                                <tr>
                                    <td><b>Average</b></td>
                                    <td colSpan='2'>{thirdTerm&&thirdTerm.average}</td>
                                </tr>
                               
                                <tr>
                                    <td colSpan='3'><b>Remarks: </b>
                                    <span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{result.thirdRemark}</span></td>
                                    
                                </tr>
                            </tfoot>
                    </table>:''}
           </div>
          
 </div> 
 

<hr/>



<table className='table table-bordered'>
         <tbody>
             <tr>
                 <td style={{maxWidth:'10px' }}><b>Head of School </b></td>
                 <td style={{maxWidth:'90px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getStaffField(String(settings.map((st)=>st.headofSchool)), 'staffName')}</td>
                 
                 <td style={{maxWidth:'5px' }}> <img id="viewPassport2" className="img-60"  src={`${ImagesUrl}/staff/signature/${getStaffField(String(settings.map((st)=>st.headofSchool)), 'signature')}`}   alt={getStaffField(result.headofSchool, 'staffName')} /></td>
             </tr>
         </tbody>
     </table>
      


 <div ref={structure} className="row no-print">
        <div className="col-md-12 ">
        
        <form method='POST' action={ServerUrl+'/printout/student_transcript.php?token='+userToken} target='_blank'>
                 <input type='hidden' name='first' value ={first.length!==0? JSON.stringify(first, null, 2):''} />
                <input type='hidden' name='firstTerm' value ={ JSON.stringify(firstTerm, null, 2)} /> 
                <input type='hidden' name='second' value ={second.length!==0? JSON.stringify(second, null, 2):''} />
                <input type='hidden' name='secondTerm' value ={ JSON.stringify(secondTerm, null, 2)} />
                <input type='hidden' name='third' value ={third.length!==0? JSON.stringify(third, null, 2):''} />
                <input type='hidden' name='thirdTerm' value ={ JSON.stringify(thirdTerm, null, 2)} />

                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />

                <input type='hidden' name='class' value ={''} />
                <input type='hidden' name='examtypeA' value ={result.examtypeA.label} />          
                <input type='hidden' name='examtypeB' value ={result.examtypeB.label} />
                <input type='hidden' name='examtypeC' value ={result.examtypeC.label} />
                <input type='hidden' name='headofSchool' value ={String(settings.map((st)=>st.headofSchool))} />




                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}


   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(StudentTranscript) 
import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import { ServerUrl, ImagesUrl, config, mainUrl} from '../component/include'
import axios from 'axios'
import Cookies from 'js-cookie'

import {encrypt} from '../component/authentication'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'


 const Login =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [user, setUser] = useState({
		username:'',
		password:'',
		remember:false,
		date:new Date().toISOString().slice(0,10),
		dateTime:new Date().toISOString().slice(0,19)
    })
    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        if(name==='remember'){
            value = !user.remember
        }	
       setUser({...user, [name] : value });
       setErrors({...errors, [name]:''});
    }
  
	

	function submit(event){  
		event.preventDefault();
		//if(navigator.onLine){
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = user;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
           let url = ServerUrl+'/login_parent_controller.php?tablename=tbl_login_user';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){
		//1000 * 60 * SESSION_IDEL_MINUTES
		var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
		var inOneMinutes = Date.now() + 418 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
		
			Cookies.set('_gaatpexpt', inSixtyMinutes, {expires: inOneHours })
			Cookies.set('_gaatpbexpt', inOneMinutes, {expires: inOneHours})

		Cookies.set('gaatpkauscd', encrypt(response.data.code), {expires: inOneHours })
		Cookies.set('gaatpkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
		Cookies.set('gaatpkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })
		Cookies.set('gaatpkstnm', encrypt(response.data.familyName),  {expires: inOneHours })


		Alerts('Welcome!', 'success', response.data.message)
		var queryString = new URLSearchParams(window.location.search).get("refferer")
		if(queryString!==null){
		  window.open(queryString, '_self')
		}else{
		  window.open('/dashboard', '_self')
		}


			}else if (response.data.type ==='error'){
				Alerts('Error!', 'danger', response.data.message)
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 window.location.reload()
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
        })    
		
	/* }else{
		Alerts('Error!', 'info', 'No internet connection')
	} */
}

const clearCookies=()=>{
	
	Cookies.remove('gaatpkauscd')	
 Cookies.remove('gaatpkamtk')
 Cookies.remove('gaatpkarfsh')
 Cookies.remove('gaatpkstnm')
 localStorage.setItem('_gaatpexpt', Date.now())
 localStorage.setItem('_gaatpbexpt', Date.now())
}

useEffect(()=>{  
 clearCookies()
 
},[]);

        return (<>
			<Preloader />
  
			<section style={{background: `url(${ImagesUrl}/background/b.jpeg) no-repeat `, backgroundSize:'100%'}} 
			className="login-block">
		  <div className="container">
			  <div className="row">
				  <div className="col-sm-12">
					  
	  <form className="md-float-material form-material"  role={'presentation'} autoComplete='off' onSubmit={submit}>
		  <div className="text-center">
		  <img src={ImagesUrl+'/about/logo.png'} className='img-100'  alt="logo.png" />
		  </div>
	  <div className="auth-box card">
		  <div className="card-block">
			  <div className="row m-b-20">
				  <div className="col-md-12">
					  <h3 className="text-center">PARENT</h3>
				  </div>
			  </div>
			  <div className="form-group form-primary">
  
				  <input type="username"  required name="username" autoComplete='off' role={'presentation'} value={user.username} placeholder="Your Username" onChange={handleChange} className={errors.username  ? 'form-control formerror' : 'form-control'} /> 
  
			  </div>
			  <div className="form-group form-primary">
			  <input type="password" id="password" name="password" autoComplete='off' role={'presentation'}  value={user.password} placeholder="Password" required onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} /> 
			  </div>
			  <div className="row m-t-25 text-left">
				  <div className="col-12">
					  <div className="checkbox-fade fade-in-primary d-">
						  <label>
							  <input type="checkbox" checked={user.remember} id="remember" name="remember" onChange={handleChange} />
							  <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-primary"></i></span>
							  <span className="text-inverse">Remember me</span>
						  </label>
					  </div>
					  <div className="forgot-phone text-right f-right">
						  <a href="#!" className="text-right f-w-600"> Forgot Password?</a>
					  </div>
				  </div>
			  </div>
			  <div className="row">
				  <div className="col-md-12">

				  {notice.isLoading ?	<button type="button" disabled className="btn btn-inverse btn-md btn-block  text-center m-b-20"><i className='fa fa-refresh fa-spin'></i> Please wait ... </button>:
				<button type="submit"  className="btn btn-inverse btn-md btn-block waves-effect waves-light text-center m-b-20">Sign in</button>}

				  </div>
			  </div>
			  <div className="row">
				  <div className="col-md-10">
					  <p className="text-inverse text-left m-b-0">Thank you.</p>
					  <p className="text-inverse text-left"><a href={mainUrl}><b className="f-w-600">Back to website</b></a></p>
				  </div>
				  <div className="col-md-2">
				  <img src={ImagesUrl+'/about/logo.png'} alt="small-logo.png" className="img-30" />
				  </div>
			  </div>
		  </div>
							  </div>
						  </form>
				  </div>
			  </div>
		  </div>
	  </section>
  
  
  
		   
  </> 


 );
        
}

export default React.memo(Login) 
import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import Select from 'react-select'
import {ServerUrl,  ImagesUrl, Token, Currency, config, schoolName,  familyCode} from '../component/include'
import { longDate, FormatNumber } from '../component/globalFunction'
import { userToken } from '../component/authentication'

const FeesRegister=()=>{
	
    const [schoolInfo, setSchoolInfo]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
const [studentList, setStudentList] = useState([]);
const [errors, setErrors] = useState({});
const [payment, setPayment] =  useState([]);

const [studentFee, setStudentFee]=useState({
    student:[],
    paymentFrom:'',
    paymentTo:'',
    sumTotal:'',
    sumPaid:'',
    sumBalance:''
});
const [fees, setFees] = useState([]);

const fetchSchoolInfo =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetch_controller/tbl_basic_details'
    axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

}

const fetchStudents =()=>{
    setNotice({...notice, isLoading: true}) 
    var sql ="SELECT admissionNumber, registerNumber, studentName, classID, sectionID, mobile, code, email, fatherName, father_mobile from tbl_students where family_name ='"+familyCode+"'"
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
    
setStudentList(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
    
})
}

const getStudentDetails =(code, field)=>{
const answer = studentList.filter(item=>item.code===code)

const result = answer.length!==0?answer[0][field]:''
return result
}

const fetchPayment =()=>{
	setNotice({...notice, isLoading: true}) 
    var sql ="SELECT CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, h.code, h.amount,  h.receiptNo, h.status, h.paymentMode, h.datePaid, h.remark from tbl_fees_charge f,  tbl_fees_payment_history h where h.feesCharge = f.code and  h.studentCode ='"+studentFee.student.value+"' "
    
    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
	
setPayment(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}


const fetchFees =()=>{
	setNotice({...notice, isLoading: true}) 
	var sql ="Select CONCAT(f.sessionCode, ' ', f.feesCharge) as fees, s.dateCreate, s.code, s.feeCharge, s.total, s.payment_status, (select sum(h.amount) from tbl_fees_payment_history h where h.scheduleCode =s.code) as paid  from tbl_fees_charge f, tbl_student_fees s where s.feeCharge = f.code and s.studentCode = '"+studentFee.student.value+"' order by f.feesCharge " ;


    const fd = new FormData();
    fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
if(result.data.length!==0){


setFees(result.data)

var  sumTotal = 0, sumPaid = 0, sumBalance = 0;
            var option = result.data
    for(var i = 0; i < option.length; i++)
    {
        sumTotal = sumTotal + Number(option[i].total);
        sumPaid = sumPaid + Number(option[i].paid);
        sumBalance = sumBalance + (Number(option[i].total)- Number(option[i].paid));
    }
    setStudentFee({...studentFee,
        sumTotal:FormatNumber(sumTotal),
        sumPaid:FormatNumber(sumPaid),
        sumBalance:FormatNumber(sumBalance)
    })
}else{
    Alerts('Info!', 'info', 'There no fees found')
}
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
	setNotice({...notice, isLoading: false})
	
})
}

const handleSelect = (option, action)=>{
	setStudentFee({...studentFee, [action.name]: option});
	setErrors({...errors, [action.name]:''})
    setFees('')
    setPayment('')
 }


    useEffect(()=>{
    fetchStudents();
    fetchSchoolInfo()
},[]);


const handleSearch=()=>{
    if(studentFee.student.length===0){
        setErrors({...errors, student:'Please select student'})
Alerts('Error!', 'danger', 'Please select student')
    }else{
         fetchFees()
	fetchPayment()
    }
   
}

   return ( <Template menu="Fees" submenu='Report'>
			
			{notice.isLoading ?<LoadEffect className="floatLoad" /> : "" } 
   <div  className="main-body">
                <div className="page-wrapper">
                 
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Student Fees Register">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Fees Register</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
								<div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Student Fees Register</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
										<div className="row">
										<section className="col-md-12">
									

			<div className="form-group">
						<label>Select Student <span style={{color:'red'}}>*</span></label>
						<Select  options={
                            studentList.map((list, idx)=> {
                               return {key:idx, value: list.code, fatherName:list.fatherName, mobile:list.father_mobile, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName+' c/o '+option.fatherName+' '+option.mobile}

onChange={handleSelect}  name="student" value={studentFee.student}  /> 

<span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>
										</section>
									</div>
										
                <div className="row">
                <section className="col-md-4">
<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Record</button>
</section>	
                                        </div>
							</div>
							</div>

							</div>
                </div>




                {fees.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table'  key={id}>
       <tbody>
                <tr>
              {/*       <td className="text-center" ><img id="viewSchoolLogo2" className="img-80" onError={(e)=>{e.target.onerror = null; e.target.src=ServerImagesUrl+'/logo.png'}} src={`${ServerImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{schoolName}</b></h4>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}>  {st.mobile} &nbsp; {st.altMobile} </div>
 <div style={{textAlign:'center'}}>  {st.email} </div>
 </td> */}

<td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Fee's Register</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Name: <b>{getStudentDetails(studentFee.student.value, 'studentName')}</b></td>
                    <td >Admission Number: <b>{getStudentDetails(studentFee.student.value, 'admissionNumber')}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
                <tr>
                    <td >Registered Number: <b>{getStudentDetails(studentFee.student.value, 'registerNumber')}</b></td>
                    <td >Email: <b>{getStudentDetails(studentFee.student.value, 'email')}</b></td>
                    <td >Mobile: <b>{getStudentDetails(studentFee.student.value, 'mobile')}</b></td>
                </tr>
            </tbody>
       </table>
        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>Date Added</th>
                <th>Fees</th>
                <th> Status</th>
                <th> Due Amount ({Currency})</th>
                <th>Paid Amount ({Currency})</th>
                <th> Balance ({Currency})</th>
                
                </tr>
            </thead>
            <tbody >
					{fees.length!==0?fees.map((dat, id)=>{
					return	<tr key={id}>
                        <td>{dat.dateCreate}</td>
					<td>{dat.fees}</td>
                    <td>{dat.payment_status}</td>
					<td>{FormatNumber(dat.total)}</td>
					<td>{FormatNumber(dat.paid)}</td>
					<td>{FormatNumber(Number(dat.total) - Number(dat.paid))}</td>
					
						</tr>
					}):[]}
				</tbody>
            <tfoot>
                <tr>
                    <td className="text-right" colSpan="3"><b>Total</b> </td>
                    <td ><b >{studentFee.sumTotal}</b></td>
                    <td ><b >{studentFee.sumPaid}</b></td>
                    <td ><b >{studentFee.sumBalance}</b></td>
                </tr>
            </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/fee_register.php?token='+userToken} target='_blank'>
                <input type='hidden' name='fees' value ={JSON.stringify(fees, null, 2)} />

                <input type='hidden' name='sumTotal' value ={studentFee.sumTotal} />
                <input type='hidden' name='sumPaid' value ={studentFee.sumPaid} />
                <input type='hidden' name='sumBalance' value ={studentFee.sumBalance} />
                <input type='hidden' name='jwtToken' value ={Token} />
                <input type='hidden' name='code' value ={studentFee.student.value} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}





        {payment.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                {/*     <td className="text-center" ><img id="viewSchoolLogo2" className="img-80" onError={(e)=>{e.target.onerror = null; e.target.src=ServerImagesUrl+'/logo.png'}} src={`${ServerImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{schoolName}</b></h4>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}>  {st.mobile} &nbsp; {st.altMobile} </div>
 <div style={{textAlign:'center'}}>  {st.email} </div>
 </td> */}

<td className="text-center" >
                    <img id="viewSchoolLogo2"  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/headerlogo.png`}   alt={schoolName}  />
 </td>
                </tr>
            </tbody>
       </table>  )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Fees History</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
       <tr>
                    <td >Name: <b>{getStudentDetails(studentFee.student.value, 'studentName')}</b></td>
                    <td >Admission Number: <b>{getStudentDetails(studentFee.student.value, 'admissionNumber')}</b></td>
                   <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
                <tr>
                    <td >Registered Number: <b>{getStudentDetails(studentFee.student.value, 'registerNumber')}</b></td>
                    <td >Email: <b>{getStudentDetails(studentFee.student.value, 'email')}</b></td>
                    <td >Mobile: <b>{getStudentDetails(studentFee.student.value, 'mobile')}</b></td>
                </tr>

            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>ReceiptNo</th>
						
                <th> Fees</th>
                <th>Payment Mode</th>
                <th> Amount ({Currency})</th>
                <th> Status</th>
                <th> Date Paid</th>
                <th> Remark</th>
                </tr>
            </thead>
            <tbody >
					{payment.length!==0?payment.map((dat, id)=>{
					return	<tr key={id}>
					<td>{dat.receiptNo}</td>
					<td>{dat.fees}</td>
					<td>{dat.paymentMode}</td>
					<td>{FormatNumber(dat.amount)}</td>
					<td>{dat.status}</td>
                    <td>{dat.datePaid}</td>
                    <td>{dat.remark!==''?<span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{dat.remark}</span>:''}</td>
						</tr>
					}):[]}
				</tbody>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/student_payments_history.php?token='+userToken} target='_blank'>
                <input type='hidden' name='studentCode' value ={studentFee.student.value} />

        <input type='hidden' name='jwtToken' value ={Token} />

        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}

          {/* The Page Body close here */}
          
            </div>
            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(FeesRegister) 
import React, {useEffect} from 'react';
import {Alerts} from './component/notify'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import ViewStudents from './students/view_students'
import StudentProfile from './students/profile'
import StudentReview from './students/student_review'

import StudentFees from './fees/student_fees'
import FeesPayment from './fees/fees_payment'
import PrintVoucher from './fees/print_voucher'
import FeeRegister from './fees/student_fee_register'

import ExamSchedule from './exam/exam_schedule'
import MockResult from './exam/mock_result'
import ViewResult from './exam/view_result'
import Transcript from './exam/student_transcript'
import MidTerm from './exam/mid-term_result'





import DisplayStudentAttendance from './attendance/display_student_attendance'
import StudentLateArrival from './attendance/student_arrival_departure_late'

import BookReturn from './library/issues_return'


import Conference from './parent/conference_log'
import family_fees from './fees/family_fees';
import Noticeboard from './parent/noticeboard'
import ChangePassword from './parent/change_password'


import hostel from './hostel/hostel';
import room_occupant from './hostel/room_occupant';

import Dashboard from './dashboard/dashboard'
import Error404 from './settings/error404';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {config, familyCode, ServerUrl, Token, familyName} from './component/include'
import {encrypt} from './component/authentication'



const ProtectedView =()=>{

const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();


fd.append('code', familyCode)
  fd.append('familyName', familyName)
fd.append('jwt', Token)


    let url = ServerUrl+'/login_refresh_parent_controller.php?tablename=tbl_refresh_parent_login';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
     
		var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
		var inOneMinutes = Date.now() + 418 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);	  
		
    Cookies.set('_gaatpexpt', inSixtyMinutes, {expires: inOneHours })
    Cookies.set('_gaatpbexpt', inOneMinutes, {expires: inOneHours})
  
      Cookies.set('gaatpkauscd', encrypt(response.data.code), {expires: inOneHours })
      Cookies.set('gaatpkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
      Cookies.set('gaatpkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })
      Cookies.set('gaatpkstnm', encrypt(response.data.familyName),  {expires: inOneHours })


      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
    window.location.href='/'
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_gaatpbexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     
useEffect(()=>{
  if (localStorage.getItem("gaatplcksr") !== null) {
    document.getElementById('openlockscreenModal').click()
 }
})


useEffect(()=>{
  //fetchContent()
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_gaatpexpt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Router  >
      <Switch>


      <Route exact path="/dashboard" component={Dashboard} />



    <Route exact path="/student/profile/:code" component={StudentProfile} /> 
 <Route exact path="/students/view_students" component={ViewStudents} />
 <Route exact path="/students/review" component={StudentReview} />

 <Route exact path="/conference" component={Conference} />
 
        <Route exact path="/fees/fees_payment" component={FeesPayment} />
        <Route exact path="/fees/print_voucher" component={PrintVoucher} />
        <Route exact path="/fees/student_fees" component={StudentFees} />
        <Route exact path="/fees/fees_register" component={FeeRegister} />

        <Route exact path="/fees/family_fees" component={family_fees} />
{/* 
        <Route exact path="/exam/mock_result" component={MockResult} />  */}
        <Route exact path="/exam/exam_schedule" component={ExamSchedule} /> 
       <Route exact path="/exam/view_result" component={ViewResult} />  
        <Route exact path="/exam/student_transcript" component={Transcript} /> 
        <Route exact path="/exam/mid_term_result" component={MidTerm} />  



        <Route exact path="/attendance/display_student_attendance" component={DisplayStudentAttendance} />
        <Route exact path="/attendance/student_arrival_departure" component={StudentLateArrival} />



<Route exact path="/library/books_report" component={BookReturn} /> 
<Route exact path="/hostel/allocation" component={hostel} /> 
    <Route exact path="/hostel/history" component={room_occupant} /> 

      <Route exact path="/noticeboard" component={Noticeboard} /> 
      <Route exact path="/change_password" component={ChangePassword} /> 


        <Route exact  component={Error404} />
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;

import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const classID ='';
export const sectionID ='';

export const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

export const staffCode= '';
export const staffName ='';



export const familyCode= Cookies.get('gaatpkauscd')?CryptoJS.AES.decrypt(Cookies.get('gaatpkauscd'), 'gaat').toString(CryptoJS.enc.Utf8):'null'
export const familyName = Cookies.get('gaatpkstnm')?CryptoJS.AES.decrypt(Cookies.get('gaatpkstnm'), 'gaat').toString(CryptoJS.enc.Utf8):'null'
export const Token = Cookies.get('gaatpkamtk')?CryptoJS.AES.decrypt(Cookies.get('gaatpkamtk'), 'gaat').toString(CryptoJS.enc.Utf8):'null'
export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'



export  const mainUrl = 'https://sms.gaatschool.com';
export  const ServerUrl = 'https://app.gaatschool.com/controller';
export  const ImagesUrl = 'https://app.gaatschool.com/images'; 
export  const StudentUrl = 'https://learners.gaatschool.com'; 
export  const LocalUrl = 'parent.gaatschool.com';
export const schoolName = 'GAAT INTERNATIONAL SCHOOL, ABUJA' 
//export  const LocalUrl = 'localhost';
/* 
export  const ServerUrl = 'http://192.168.64.3/kayus/gaat/controller';
export  const ImagesUrl = 'http://192.168.64.3/kayus/gaat/images'; 
export  const StudentUrl = 'https://learner.gaatschool.com'; 
export const schoolName = 'GAAT INTERNATIONAL SCHOOL, ABUJA'
export  const LocalUrl = 'localhost'; */

